@import '../../../styles/customMediaQueries.css';
@import '../../../styles/gridDefaults.scss';

$newBlueTextColor: #0e3c77;
$pageMaxWidth: 1089px;

.root {
  margin: 0 0 0 0;

  @media (--viewportMedium) {
    margin: 105px 0 0 0;
  }

  @media (--viewportLarge) {
    margin: 105px 0 0 0;
  }
}

.paragraphUnsetMarginTB {
  margin-top: unset;
  margin-bottom: unset;
}
.namedLink {
  &:hover {
    text-decoration: none;
  }
}
.baseCtaButton {
  background-color: var(--colorBlueButton);
  color: var(--colorWhite);
  &:hover {
    background-color: var(--colorWhite);
    color: var(--colorBlueButton);
  }
}

.baseSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;

  @media (--viewportMedium) {
    padding: 30px 20px;
  }
}
.baseSectionTitle {
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}
.baseSectionSubTitle {
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  max-width: 330px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 50px;
    max-width: 900px;
  }
}

.sectionHero {
  width: 100%;

  .heroFrameWrapper {
    background-color: var(--colorWhite);
    width: 100%;
    height: 100%;

    .heroGroup {
      background-image: url('./images/sectionHero/sectionHeroBackground.png');
      background-position: 50% 50%;
      background-size: cover;
      height: 591px;

      @media (--viewportMedium) {
        height: 811px - 25px;
      }

      .heroBackdrop {
        background-color: rgba(0, 0, 0, 0.4);
        height: 100%;

        .heroContent {
          max-width: 798px;
          height: 100%;
          margin: auto auto;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0 25px 0 25px;

          @media (--viewportLargeWithPaddings) {
            padding: 0;
          }

          @media (--viewportMedium) {
            max-width: $pageMaxWidth;
          }

          .colContent {
            flex: $flexColDefault;
            width: 100%;
            @media (--viewportMedium) {
              width: 66%;
            }
          }

          .title {
            text-align: left;
            font-size: 32px;
            font-weight: var(--fontWeightBold);
            color: var(--colorWhite);
            line-height: 3rem;

            @media (--viewportMedium) {
              font-size: 56px;
              line-height: 4rem;
            }
          }

          .subTitle {
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: var(--fontWeightSemiBold);
            color: var(--colorWhite);

            @media (--viewportMedium) {
              font-size: 24px;
            }
          }

          .becomeAMemberButton {
            @extend .baseCtaButton;
            max-width: calc(525px / 2);
            font-size: 20px;
            padding: 15px 0;
            @media (--viewportMedium) {
              max-width: 525px;
            }
            border-radius: 8px;
            min-height: 38px;
            margin: 20px 0;
            text-transform: uppercase;
            font-weight: var(--fontWeightBold);
          }
        }
      }
    }
  }
}

.sectionBenefits {
  @extend .baseSection;
  background-color: $newBlueTextColor;
  padding: 30px;

  @media (--viewportLarge) {
    padding: 30px 20px;
  }

  .title {
    margin: unset;
    text-align: center;
    font-size: 20px;
    font-weight: var(--fontWeightBold);
    color: var(--colorWhite);

    @media (--viewportMedium) {
      font-size: 36px;
    }
  }

  .membershipTitle {
    margin: unset;
    text-align: center;
    margin-top: 5px;
    font-size: 24px;
    font-weight: var(--fontWeightHeavy);
    color: var(--colorWhite);
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @media (--viewportMedium) {
      margin-top: -15px;
      font-size: 36px;
    }
  }

  .desktop {
    display: none;
    @media (--viewportMedium) {
      display: block;
    }
  }
  .mobile {
    display: block;
    @media (--viewportMedium) {
      display: none;
    }
  }

  $propertyItemRow1Width: 518px;
  .propertyItemRow1 {
    width: 100%;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusLarge);
    margin-top: 15px;
    padding: 15px;
    @media (--viewportLarge) {
      width: calc($propertyItemRow1Width - ($propertyItemRow1Width * 0.15));
      padding: 12px 24px;
      min-height: 171px;
    }
    @media (--viewportLargeWithPaddings) {
      width: $propertyItemRow1Width;
    }
  }

  $colValuesMarginSize: 15px;
  .colBenefit {
    flex: $flexColDefault;
    width: 100%;
    @media (--viewportLarge) {
      flex: $flexColDefault;
      width: calc((100% - (2 * $colValuesMarginSize)) / 2);
    }
    @media (--viewportLarge) {
      margin-right: $colValuesMarginSize;
    }
  }

  .benefitsTitle {
    font-size: 14px;
    font-weight: var(--fontWeightBold);
    color: var(--colorBlueTextDark);
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 13px;
    text-transform: uppercase;
    @media (--viewportLarge) {
      font-size: 16px;
    }
  }

  .benefitsDescription {
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    color: $newBlueTextColor;
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 13px;
    @media (--viewportLarge) {
      font-size: 16px;
    }
  }

  .exploreMembershipButton {
    @extend .baseCtaButton;
    min-width: calc(397px / 2);
    max-width: 397px;
    font-size: 14px;
    padding: 15px 0;
    @media (--viewportMedium) {
      min-width: 397px;
    }
    @media (--viewportLargeWithPaddings) {
      font-size: 20px;
    }
    border-radius: 8px;
    min-height: 38px;
    margin: 20px auto 0 auto;
    text-transform: uppercase;
    font-weight: var(--fontWeightBold);
  }
}

.sectionPortfolio {
  @extend .baseSection;

  .title {
    @extend .paragraphUnsetMarginTB;
    font-size: 12px;
    font-weight: var(--fontWeightSemiBold);
    text-transform: uppercase;
    text-align: left;
    color: var(--colorBlueTextDark);
    letter-spacing: 0.1em;
    margin-left: 10px;

    @media (--viewportMedium) {
      font-size: 20px;
    }
  }

  .subTitle {
    @extend .paragraphUnsetMarginTB;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: var(--fontWeightBold);
    color: var(--colorBlueTextDark);
    text-align: left;
    margin-left: 10px;

    @media (--viewportMedium) {
      margin-top: -15px;
      font-size: 32px;
    }
  }

  .colPortfolio {
    flex: unset;
    width: unset;
    @media (--viewportLargeWithPaddings) {
      flex: $flexColDefault;
      width: 33.33%;
    }
  }

  .listingCardItem {
    max-width: 267px;
    min-height: calc(454px - 25%);
    max-height: calc(454px);
    @media (--viewportMedium) {
      max-width: 368px;
      min-height: 398px;
      max-height: 398px;
    }
    @media (--viewportLarge) {
      max-width: 368px;
      min-height: 454px;
      max-height: 454px;
    }
    margin: 0 5px;
    border-radius: var(--borderRadiusLarge);
    background-color: $newBlueTextColor;

    @media (--viewportMedium) {
      margin: 0 10px;
    }

    img {
      border-radius: var(--borderRadiusLarge);
      width: 100%;
      max-width: 368px;
      min-height: 150px;
      max-height: 200px;
      object-fit: cover;
      @media (--viewportXSmall) {
        min-height: 225px;
        max-height: 225px;
      }
      @media (--viewportSmall) {
        min-height: 250px;
        max-height: 250px;
      }
      @media (--viewportLarge) {
        min-height: 298px;
        max-height: 298px;
      }
    }

    .content {
      padding: 15px;

      h3 {
        @extend .paragraphUnsetMarginTB;
        color: var(--colorWhite);
        font-size: 16px;
        font-weight: var(--fontWeightBold);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .state {
          font-size: 14px;
          font-weight: var(--fontWeightRegular);
        }

        @media (--viewportMedium) {
          font-size: 18px;
        }
      }

      p {
        @extend .paragraphUnsetMarginTB;
        margin-top: 5px;
        color: var(--colorWhite);
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (--viewportSmall) {
          font-size: 14px;
        }

        @media (--viewportMedium) {
          margin-top: -15px;
          font-size: 16px;
        }
      }
    }
  }
}

.sectionReviews {
  @extend .baseSection;
  padding: 30px 0 0 0;
  background-color: var(--colorBlueLight);
}

.sectionWorldClass {
  @extend .baseSection;

  .desktop {
    display: none;
    @media (--viewportLarge) {
      display: block;
    }
  }
  .mobile {
    display: block;
    @media (--viewportLarge) {
      display: none;
    }
  }

  .worldClassContainer {
    max-width: 1274px;
    background-color: $newBlueTextColor;
    padding: 40px;
    border-radius: var(--borderRadiusLarge);

    .content {
      padding: 20px;

      @media (--viewportLarge) {
        padding: 0 20px 0 40px;
      }

      .title {
        color: var(--colorWhite);
        font-size: 20px;
        font-weight: var(--fontWeightBold);

        @media (--viewportLarge) {
          font-size: 32px;
        }
      }

      .description {
        margin-top: -15px;
        color: var(--colorWhite);
        font-size: 16px;

        @media (--viewportLarge) {
          font-size: 20px;
        }
      }

      .exploreMembershipButton {
        @extend .baseCtaButton;
        max-width: 359px;
        font-size: 14px;
        padding: 15px 0;
        @media (--viewportLargeWithPaddings) {
          font-size: 20px;
        }
        border-radius: 8px;
        min-height: 38px;
        margin: 20px 0 0 0;
        text-transform: uppercase;
        font-weight: var(--fontWeightBold);
      }
    }

    .sectionWorldClassCollageWrapper {
      float: unset;
      padding: 0;
      width: 100%;
      img {
        width: 100%;
        margin: 0 auto;
        display: block;
        @media (--viewportSmall) {
          width: unset;
        }
      }
      @media (--viewportLarge) {
        float: right;
        padding: 40px;
        margin: unset;
      }
    }
  }
}

.sectionSlideshow {
  width: 100%;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }

  .slideshowWrapper {
    background-color: var(--colorWhite);
    width: 100%;
    height: 100%;

    .slideshowGroup {
      background-image: url('./images/sectionSlideshow/slideshowImage1.png');
      background-position: 50% 25%;
      background-size: cover;
      width: 100%;
      height: auto;
      height: 500px;

      .slideshowBackdrop {
        // background-color: rgba(0, 0, 0, 0.4);
        // width: 100%;
        // height: auto;
        // min-height: 500px;

        h1 {
          max-width: 835px;
          padding: 35px 0 0 0;
          margin: 0 auto;
          color: var(--colorBlueTextDark);
          text-align: center;
          font-size: 32px;
          font-weight: var(--fontWeightBold);
          line-height: 3rem;
          @media (--viewportMedium) {
            line-height: unset;
          }
        }

        .slideshowImage {
          width: 100%;
        }
      }
    }
  }
}

.blueRibbon {
  bottom: -200px;
  left: 12px;
  position: relative;
  max-width: 360px;
  line-height: 1.25;
  width: 100%;
  background-color: $newBlueTextColor;
  color: var(--colorWhite);
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  padding: 10px;
}
