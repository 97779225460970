@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/marketplaceDefaults.css';
@import '../../../../styles/gridDefaults.scss';

.root {
  padding-left: 30px;
  border-radius: 8px;
  margin: 0 auto;
  max-width: var(--contentMaxWidthPages);
  width: 100vw;
  padding-top: 30px;

  @media (--viewportMedium) {
    max-width: var(--contentMaxWidthPages);
    padding-left: 0;
    border-radius: 16px;
    padding-top: 62px;
    width: 100%;
  }
}

.container {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 34px 0;
  gap: 10px;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    max-width: var(--contentMaxWidthPages);
    padding: 62px 0;
    gap: 40px;
  }
}

.title {
  color: var(--colorBlueTextDark);
  font-weight: var(--fontWeightBold);
  text-align: left;
  font-size: 16px;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 32px;
    margin-bottom: 40px;
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}

.cardsDataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 26px;
  width: 100%;
}

.sliderContainer {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding-bottom: 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding-bottom: 62px;
    width: 100vw;
    padding: 0 24px 24px 24px;
  }

  @media (--viewportLarge) {
    width: 100%;
    padding: 0 0 24px 0;
  }
}

.namedListingLink {
  &:hover {
    text-decoration: none;
  }
}

.sliderContainer :global(.slick-next:before),
.sliderContainer :global(.slick-prev:before) {
  color: var(--colorGrey300) !important;
}

.sliderContainer :global(.slick-next) {
  left: calc(100vw - 60px);

  @media (--viewportMedium) {
    left: inherit;
  }
}

.sliderContainer :global(.slick-slide) {
  @media (--viewportMedium) {
    max-width: 386px !important;
    max-height: 520px;
    height: auto;
  }
}

.sectionItem {
  max-width: 302px;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  @media (--viewportMedium) {
    width: 100%;
    min-width: 360px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.2s;
  }

  &:hover {
    transform: translateY(-5px);
  }
}

.campingBadge {
  position: absolute;
  left: 0;
  top: 30px;
  background-color: var(--colorBlueLight);
  color: var(--colorBlueButton);
  padding: 5px 10px;
  font-weight: var(--fontWeightRegular);
  text-transform: uppercase;
  font-size: 12px;

  @media (--viewportMedium) {
    padding: 5px 20px;
    font-size: 15px;
    font-weight: var(--fontWeightBold);
  }
}

.itemContainer {
  position: relative;
  height: 100%;
  border-radius: 8px;
}

.imgContainer {
  flex: 50%;
  overflow: hidden;
  height: 100%;
}

.image {
  height: 210px;
  background-size: cover;
  background-position: center center;
  border-radius: 8px 8px 0px 0px;
}

.contentContainer {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  height: calc(100% - 210px);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  max-height: 100px;
}

.contentTitle {
  color: var(--colorBlueDark);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 0;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.location {
  color: var(--colorBlueDark);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fontWeightMedium);
  line-height: normal;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.content {
  color: var(--colorBlueDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5em;
  max-height: 180px;
  min-height: 180px;
  overflow: auto;
  margin: 10px 0;
}
