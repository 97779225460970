@import '../../../styles/customMediaQueries.css';
@import '../../../styles/gridDefaults.scss';

$maxVideoHeightSize: 798px;
$pageMaxWidth: 1089px;

.namedLink {
  &:hover {
    text-decoration: none;
  }
}
.baseCtaButton {
  background-color: var(--colorBlueButton);
  color: var(--colorWhite);
  &:hover {
    background-color: var(--colorWhite);
    color: var(--colorBlueButton);
  }
}

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    padding-bottom: 0;
    margin-top: 80px;
  }
}

.videoContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: calc($maxVideoHeightSize - calc($maxVideoHeightSize * 0.2));

  @media (--viewportMedium) {
    max-height: $maxVideoHeightSize;
  }

  .videoBgLanding {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: calc($maxVideoHeightSize - calc($maxVideoHeightSize * 0.2));

    @media (--viewportMedium) {
      max-height: $maxVideoHeightSize;
    }
  }
}

.opacityImg {
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(180deg, #002c4c 58%, #f2295b00 100%);
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

  width: 100%;
  height: 100%;
  max-height: calc($maxVideoHeightSize - calc($maxVideoHeightSize * 0.2));

  @media (--viewportMedium) {
    max-height: $maxVideoHeightSize;
  }
}

.sectionContent {
  padding: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: calc(100vh - 122px);
  display: flex;
  padding: 0 25px 0 25px;

  height: calc($maxVideoHeightSize - calc($maxVideoHeightSize * 0.2));

  @media (--viewportLarge) {
    height: $maxVideoHeightSize;
  }

  &::after {
    content: url('./media/bottomMobilImg.webp');
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    bottom: 161px;

    @media (--viewportMedium) {
      bottom: -22px;
      content: none;
    }
  }
}

.bgImgContent {
  z-index: 1;
  margin: 0 auto;
  max-width: 100%;

  @media (--viewportMedium) {
    max-width: $pageMaxWidth;
  }

  padding: 0 20px;
  @media (min-width: 1450px) {
    padding-left: 20px;
  }

  .title {
    max-width: 798px;
    font-size: 32px !important;
    font-weight: var(--fontWeightBold);
    text-align: left;
    line-height: 3rem;
    letter-spacing: normal;
    margin: 0 0 10px 0;
    padding: 0;
    color: var(--colorWhite);

    @media (--viewportMedium) {
      font-size: 56px !important;
      line-height: 4rem;
    }
  }

  .subTitle {
    color: var(--colorWhite);
    text-align: left;
    font-size: 16px !important;
    font-weight: var(--fontWeightSemiBold);
    margin-bottom: 20px;
    width: calc(100vw - 70px);
    max-width: 700px;
    line-height: 1.3em;
    margin-top: 0;

    @media (--viewportMedium) {
      font-size: 24px !important;
      margin-bottom: 20px;
      line-height: 42px;
    }
  }

  .becomeAMemberButton {
    @extend .baseCtaButton;
    max-width: calc(525px / 2);
    font-size: 20px;
    padding: 15px 0;
    @media (--viewportMedium) {
      max-width: 525px;
    }
    border-radius: 8px;
    min-height: 38px;
    margin: 20px 0;
    text-transform: uppercase;
    font-weight: var(--fontWeightBold);
  }
}
